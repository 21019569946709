import React from 'react';

export default function GoogleTrends({type, keyword, period, url}) {
    React.useEffect(() => {
        if (period != undefined && keyword.length != 0) {
            const script = document.createElement('script');
            script.src = url;
            script.async = true;
            script.onload = () => {
                handleScriptLoad();
            };

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [period, keyword]);

    // selectedPeriodが変更されると、現在のダッシュボードを削除
    React.useEffect(() => {
        deleteDashboard();
    }, [period, keyword]);

    const deleteDashboard = () => {
        const widget = document.querySelectorAll('iframe');
        if (widget.length != 0) {
            widget[0].remove();
        }
    };

    const handleScriptLoad = (_) => {
        let comparisonItem = [];
        if (keyword.length == 1) {
            comparisonItem = [{keyword: keyword[0], geo: 'JP', time: period}];
        } else if (keyword.length == 2) {
            comparisonItem = [
                {keyword: keyword[0], geo: 'JP', time: period},
                {keyword: keyword[1], geo: 'JP', time: period},
            ];
        } else if (keyword.length == 3) {
            comparisonItem = [
                {keyword: keyword[0], geo: 'JP', time: period},
                {keyword: keyword[1], geo: 'JP', time: period},
                {keyword: keyword[2], geo: 'JP', time: period},
            ];
        }
        window.trends.embed.renderExploreWidgetTo(
            document.getElementById('widget'),
            type,
            {
                comparisonItem: comparisonItem,
                category: 0,
                property: '',
            },
            {
                exploreQuery: `q=${encodeURI(keyword)}&geo=JP&date=today ${period}`,
                guestPath: 'https://trends.google.com:443/trends/embed/',
            },
        );
    };
}
