import * as React from 'react';
import {ThemeProvider, createTheme} from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import axios from 'axios';
import {jaJP} from '@mui/x-date-pickers/locales';
import {TwitterTweetEmbed} from 'react-twitter-embed';
import {ModalHandlingContext} from '../../pages/CheckLimit';
import {ExtendDateContext} from '../../pages/CheckLimit';
import {DateCalendar, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {format} from 'date-fns';
import Grid from '@mui/system/Unstable_Grid/Grid';
// import {CredentialContext} from '../Auth';
import ja from 'date-fns/locale/ja';
import {StateConstantContext} from '../../constants/StateConstant';

const LimitExtendModal = (props) => {
    const today = new Date();
    const [selectedDate, setSelectedDate] = React.useState(today);
    const [extendDate, setExtendDate] = React.useState('');
    const {openSubmitModal, submitHandleClose} = React.useContext(ModalHandlingContext);
    const {getOverdueData} = React.useContext(ExtendDateContext);
    const url = process.env.REACT_APP_API_URL;
    // const {credential, attribute} = React.useContext(CredentialContext);
    // const [idToken, setIdToken] = React.useState(credential.signInUserSession.idToken.jwtToken);
    const {idToken} = React.useContext(StateConstantContext);

    // React.useEffect(() => {
    //     if (attribute) {
    //         if (Object.keys(attribute.attributes).includes('custom:default_account')) {
    //             setIdToken(credential.signInUserSession.idToken.jwtToken);
    //         }
    //     }
    // }, [attribute]);

    // 参考サイト https://qiita.com/naninanya/items/6c0899c1accf14dbbdd1
    class DateAdapter extends AdapterDateFns {
        getWeekdays = () => {
            return ['日', '月', '火', '水', '木', '金', '土'];
        };
    }

    const theme = createTheme(
        {},
        jaJP, // x-date-pickers translations
    );

    const onChangeHandler = (e) => {
        if (!e) return;
        setSelectedDate(new Date(e.getFullYear(), e.getMonth(), e.getDate()));
        const formatDay = format(e, 'yyyy-MM-dd');
        setExtendDate(formatDay + 'T00:00:00.000Z');
    };

    const ModalDatePicker = () => {
        return (
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={DateAdapter} adapterLocale={ja} dateFormats={{monthAndYear: 'yyyy年 MM月', monthShort: 'MM月'}}>
                    <Box
                        sx={{
                            '&& .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel, && .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root': {fontSize: '1rem'},
                            '&& .css-cyfsxc-MuiPickersCalendarHeader-labelContainer': {fontSize: '1.2rem'},
                        }}
                    >
                        <DateCalendar views={['month', 'day']} value={selectedDate} minDate={today} onChange={onChangeHandler} />
                    </Box>
                </LocalizationProvider>
            </ThemeProvider>
        );
    };

    const extendedDatePost = (e, g) => {
        e = '';
        if (e == '') {
            // alert('日付を選択してください');
            alert('今はまだ押さないでください');
        } else {
            axios.post(url + 'expired?request_day=' + e + '&post_id=' + g, '', {headers: {Authorization: idToken}}).then((response) => {
                if (response.status == 200) {
                    alert('修正完了しました。');
                    getOverdueData();
                } else {
                    alert('【エラー】修正失敗しました。');
                }
                submitHandleClose();
            });
        }
    };

    return (
        <Modal open={openSubmitModal} onClose={submitHandleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description' sx={{overflow: 'scroll'}}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    height: '600px',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    overflow: 'auto',
                    borderRadius: '15px',
                }}
                component='form'
                noValidate
                autoComplete='off'
            >
                <Box sx={{fontSize: '30px', fontWeight: 'bold', textAlign: 'center'}}>期限修正</Box>
                <Box sx={{fontSize: '14px', fontWeight: 'normal', textAlign: 'center'}}>※cockpit上での表示のみ変更されます。不要になった際には速やかに削除をお願いします。</Box>
                {(() => {
                    if (props.selectedRowsLength == 0) {
                        return <Box sx={{textAlign: 'center', fontSize: '20px'}}>期限修正したいツイートを選択してください</Box>;
                    } else if (props.selectedRowsLength > 1) {
                        return <Box sx={{textAlign: 'center', fontSize: '20px'}}>1件のみ選択してください</Box>;
                    } else if (props.selectedRowsLength == 1) {
                        return (
                            <>
                                <Grid container>
                                    <Grid xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                                        <Box sx={{width: '300px', marginTop: '10'}}>
                                            <TwitterTweetEmbed id={props.selectedOverdueTweetID} tweetId={props.selectedOverdueTweetID} />
                                        </Box>
                                    </Grid>
                                    <Grid xs={6}>
                                        <h3>ID {props.selectedOverdueTweetID}</h3>
                                        <h3>投稿期限 {props.selectedOverdueExpiredDate.substr(0, 10)}</h3>
                                        <Box>
                                            <ModalDatePicker />
                                        </Box>
                                        <Box sx={{margin: '0 0 0 0', display: 'flex', justifyContent: 'center'}}>
                                            <Button
                                                variant='contained'
                                                sx={{
                                                    margin: '10px',
                                                }}
                                                onClick={() => {
                                                    extendedDatePost(extendDate, props.selectedOverduePostId);
                                                }}
                                            >
                                                選択した日付を送信
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </>
                        );
                    }
                })()}
            </Box>
        </Modal>
    );
};
export default LimitExtendModal;
