// 必要なライブラリのインポート
import * as React from 'react';
// import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';


// DataGridを設定する。
// 上で最初に用意したレコードを、テーブルに流し込んでいます。
export default function Table(props) {

    // ベースとなる行列を定義する(json取得後に各プラットフォームのデータを更新することでテーブルに値を表示させる)
    let rows=[]

    if (props.dataGridItems && props.dataGridItems.length > 0) {
        rows = props.dataGridItems
    }

    // カラムを設定する
    // テーブルにどんな列があって、各列にどんな値を入れるかを定義しています。
    const columns = [
        {
            field: 'rank',
            headerName: '順位',
            width: 100,
            editable: false
        },
        {
            field: 'yahoo',
            headerName: 'Xトレンド（Yahoo!データ）',
            // description: 'X（旧Twitter）に投稿されたポスト（つぶやき）などの各種データソースを元に機械的に抽出されているデータです。「Yahoo!リアルタイム検索」のページでも閲覧できます。',
            flex: 1,
            maxWidth: 360,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                if (params.value && params.value.word != '') {
                    return (
                        <a
                            style={{ color: 'inherit', textDecoration: 'none', fontSize: '' }}
                            href={'https://search.yahoo.co.jp/realtime/search?p=' + params.value.word}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {params.value.word}
                        </a>
                    );
                }
            },
        },
        {
            field: 'google',
            headerName: 'Googleトレンド',
            // description: '過去 24 時間内に Google サービス全体で話題になったトピックスのデータです。Google のアルゴリズムで検出されたナレッジグラフのトピック、検索インタレスト、注目の YouTube 動画、Google ニュースの記事が該当します。',
            flex: 1,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                if (params.value && params.value.word != '') {
                    return (
                        <a
                            style={{ color: 'inherit', textDecoration: 'none', fontSize: '' }}
                            href={params.value.url}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {params.value.word}
                        </a>
                    );
                }
            },
        }
    ];


    return (
        <Box sx={{ width: 'auto' }}>
            <DataGrid
                autoHeight
                getRowHeight={() => 'auto'}
                rows={rows}
                columns={columns}
                alignItems="center"
                hideFooter
                disableRowSelectionOnClick
                sx={{
                    [`& .MuiDataGrid-cell`]: {
                        paddingTop: 1.3,
                        paddingBottom: 1.3,
                        lineHeight: 'unset !important',
                        maxHeight: 'none !important',
                        whiteSpace: 'normal',
                    },
                }}
            />
        </Box>
    );
}
