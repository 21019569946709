import {styled} from '@mui/material/styles';
import MuiButton from '@mui/material/Button';

export const Button = styled(MuiButton)((props) => ({
    borderRadius: '30px',
    border: 'solid 1px #2E5077',
    backgroundColor: 'white',
    '&:hover': {
        boxShadow: 'inset 0px 0px 0px 2px #2E5077',
        backgroundColor: '#fff',
    },
}));
