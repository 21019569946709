import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import GoogleTrendsGraph from '../components/GoogleTrendsGraph';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import {Select} from '../components/Select';
import TextField from '@mui/material/TextField';
import {Button} from '../components/Button';
import Link from '@mui/material/Link';

export default function Trends() {
    const [keywords, setKeywords] = React.useState([]);
    const [keyword1, setKeyword1] = React.useState('');
    const [keyword2, setKeyword2] = React.useState('');
    const [keyword3, setKeyword3] = React.useState('');
    const [selectedPeriod, setSelectedPeriod] = React.useState('now 7-d');
    const [period, setPeriod] = React.useState('now 7-d');

    const Form = () => {
        const periods = [
            {name: '24時間', period: 'now 1-d'},
            {name: '7日間', period: 'now 7-d'},
            {name: '30日間', period: 'today 1-m'},
        ];
        const handleChange = (f) => {
            setPeriod(f.target.value);
        };

        return (
            <FormControl sx={{width: 300, margin: '0 20 0 0'}}>
                <InputLabel id='account-select-label'>期間選択</InputLabel>
                <Select
                    labelId='account-select-label'
                    id='account-select'
                    // defaultValue={props.defaultAuthorId}
                    label='期間選択'
                    onChange={handleChange}
                    sx={{borderRadius: '50px'}}
                    value={period || ''}
                >
                    {periods.map((e, i) => {
                        return (
                            <MenuItem key={i} value={e.period}>
                                {e.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    };

    const setKeyword = () => {
        if (keyword1 != '') {
            setKeywords([keyword1]);
            if (keyword2 != '') {
                setKeywords([keyword1, keyword2]);
                if (keyword3 != '') {
                    setKeywords([keyword1, keyword2, keyword3]);
                }
            }
        } else {
            setKeywords([]);
        }
        setSelectedPeriod(period);
    };

    return (
        <Container width='70%'>
            <Grid item xs={12} sx={{width: '100%', maxWidth: '1000px', textAlign: 'left'}}>
                <Box
                    sx={{
                        margin: '10px 0 0 0',
                        textAlign: 'right',
                        fontSize: '16px',
                    }}
                >
                    <Link href='/trends' color='inherit'>
                        ソーシャルトレンドはこちら
                    </Link>
                </Box>
            </Grid>
            <Grid container justifyContent='center' sx={{textAlign: 'center'}}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            fontWeight: 'bold',
                            fontSize: 26,
                            margin: '10 0 10 0',
                        }}
                    >
                        Googleトレンド比較
                    </Box>
                    <Box
                        sx={{
                            color: 'gray',
                            fontSize: 14,
                            margin: '0 0 30 0',
                        }}
                    >
                        <p>複数キーワードでトレンドの推移を調べることができます</p>
                        <p>入力例：紅麹、紅こうじ、べにこうじ</p>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        margin: '10 10 30 10',
                    }}
                >
                    <TextField
                        id='outlined-basic'
                        label='キーワード１'
                        value={keyword1}
                        onChange={(event) => {
                            setKeyword1(event.target.value);
                        }}
                        sx={{
                            margin: '0 10',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#2E5077',
                            },
                        }}
                    />
                    <TextField
                        id='outlined-basic'
                        label='キーワード２'
                        variant='outlined'
                        value={keyword2}
                        onChange={(event) => {
                            setKeyword2(event.target.value);
                        }}
                        sx={{
                            margin: '0 10',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#2E5077',
                            },
                        }}
                    />
                    <TextField
                        id='outlined-basic'
                        label='キーワード３'
                        variant='outlined'
                        value={keyword3}
                        onChange={(event) => {
                            setKeyword3(event.target.value);
                        }}
                        sx={{
                            margin: '0 10',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#2E5077',
                            },
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        margin: '10 0 30 0',
                    }}
                >
                    <Form />
                    <Button
                        sx={{
                            width: 150,
                            margin: '10 0 30 30',
                        }}
                        onClick={() => setKeyword()}
                    >
                        表示
                    </Button>
                </Grid>
            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    width: '100%',
                    margin: '0 0 50 0',
                }}
                justifyContent='center'
            >
                <div id='widget' style={{width: '90%'}}>
                    <GoogleTrendsGraph type='TIMESERIES' keyword={keywords} period={selectedPeriod} url='https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js' />
                </div>
            </Box>
        </Container>
    );
}
