import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import {BrowserAgent} from '@newrelic/browser-agent/loaders/browser-agent';

// new relic対応
const options = {
    init: {distributed_tracing: {enabled: true}, privacy: {cookies_enabled: true}, ajax: {deny_list: ['bam.nr-data.net']}}, // NREUM.init
    info: {beacon: 'bam.nr-data.net', errorBeacon: 'bam.nr-data.net', licenseKey: 'NRBR-fd4fa521bb95b295e53', applicationID: '594494469', sa: 1}, // NREUM.info
    loader_config: {accountID: '4471268', trustKey: '3778959', agentID: '594494469', licenseKey: 'NRBR-fd4fa521bb95b295e53', applicationID: '594494469'}, // NREUM.loader_config
};

const container = document.getElementById('root');
const root = createRoot(container);

new BrowserAgent(options); // new relic対応

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
);