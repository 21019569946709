import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import {ModalHandlingContext} from '../../pages/CheckLimit';
import {ExtendDateContext} from '../../pages/CheckLimit';

const CheckDeletedTweetModal = (props) => {
    const {openCheckModal, checkHandleClose} = React.useContext(ModalHandlingContext);
    const {selectedOverdueTweetID, getOverdueData} = React.useContext(ExtendDateContext);
    const url = process.env.REACT_APP_API_URL;

    const submitCheckDeletedTweet = (e) => {
        const body = '{"tweet_ids": ["1629224651657281542"]}';
        try {
            axios.put(url + 'delete-check?author_id=' + props.authorId + '&username=' + props.selectedUsername, body).then((response) => {
                if (response.status == 200) {
                    alert(displayChangeItems(response.data));
                    getOverdueData();
                } else {
                    alert('【エラー】修正失敗しました。');
                }
                checkHandleClose();
            });
        } catch (error) {
            alert('【エラー】修正失敗しました。');
            checkHandleClose();
        }
    };

    const displayChangeItems = (props) => {
        const textArrey = [];
        let text = '';
        props.forEach((e) => {
            if (e.overdue_deleted === false) {
                text = 'id ' + e.id + 'のツイートは存在するため、データベースからの削除は行いませんでした。';
            } else if (e.overdue_deleted === true) {
                text = 'id ' + e.id + 'のツイートは削除されているため、データベースから削除しました。';
            }
            textArrey.push(text);
        });
        return textArrey;
    };

    return (
        <Modal open={openCheckModal} onClose={checkHandleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description' sx={{overflow: 'scroll'}}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    height: '500px',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    overflow: 'scroll',
                    borderRadius: '15px',
                }}
            >
                <Box sx={{fontSize: '30px', fontWeight: 'bold', textAlign: 'center'}}>削除確認</Box>
                {(() => {
                    if (props.selectedRowsLength == 0) {
                        return <Box sx={{textAlign: 'center', fontSize: '20px'}}>ツイートを1件選択してください</Box>;
                    } else if (props.selectedRowsLength > 1) {
                        return <Box sx={{textAlign: 'center', fontSize: '20px'}}>1件のみ選択してください</Box>;
                    } else if (props.selectedRowsLength == 1) {
                        return (
                            <>
                                <h3>ID {selectedOverdueTweetID} の</h3>
                                <h3>削除確認をします</h3>

                                <Box>
                                    <Button
                                        variant='contained'
                                        sx={{
                                            margin: '10px',
                                        }}
                                        onClick={() => {
                                            submitCheckDeletedTweet();
                                        }}
                                    >
                                        確認
                                    </Button>
                                </Box>
                            </>
                        );
                    }
                })()}
            </Box>
        </Modal>
    );
};
export default CheckDeletedTweetModal;
