import * as React from 'react';
import {Amplify, Auth} from 'aws-amplify';
import {useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {StateConstantContext} from '../constants/StateConstant';

export const CredentialContext = React.createContext();

export default function Authenticate({children}) {
    const url = useLocation().pathname + useLocation().search;
    const navigate = useNavigate();
    const locate = useLocation();
    const cognitoCustomKeys = {
        X: 'custom:def_account_x',
        INSTAGRAM: 'custom:def_account_ig',
    };
    const {setAuthorIdX, setAuthorIdIg, setDefaultAuthorIdX, setDefaultAuthorIdIg, credential, setCredential, attribute, setAttribute, setIdToken, name, setName} =
        React.useContext(StateConstantContext);
    // const {setAuthorIdIg, setDefaultAuthorIdX, setDefaultAuthorIdIg, credential, setCredential, attribute, setAttribute, setIdToken} = React.useContext(StateConstantContext);

    React.useEffect(() => {
        authenticate();
        setName(new URLSearchParams(locate.search).get('name'));
    }, []);
    // defaultAuthorIdがセットされていない場合は、default_accountをセットする画面に遷移
    React.useEffect(() => {
        if (attribute) {
            if (Object.keys(attribute.attributes).includes(cognitoCustomKeys['X'])) {
                setDefaultAuthorIdX(attribute.attributes[cognitoCustomKeys['X']]);
                setIdToken(credential.signInUserSession.idToken.jwtToken);
                if (!name) {
                    setAuthorIdX(attribute.attributes[cognitoCustomKeys['X']]);
                }
                // setAuthorIdX(attribute.attributes[cognitoCustomKeys['X']]);
            }
            if (Object.keys(attribute.attributes).includes(cognitoCustomKeys['INSTAGRAM'])) {
                setDefaultAuthorIdIg(attribute.attributes[cognitoCustomKeys['INSTAGRAM']]);
                setIdToken(credential.signInUserSession.idToken.jwtToken);
                if (!name) {
                    setAuthorIdIg(attribute.attributes[cognitoCustomKeys['INSTAGRAM']]);
                }
            }
            if (!Object.keys(attribute.attributes).includes(cognitoCustomKeys['X']) && !Object.keys(attribute.attributes).includes(cognitoCustomKeys['X'])) {
                console.log('default_account is not set');
                navigate('/default_account');
            }
        }
    }, [attribute]);

    React.useEffect(() => {
        if (credential) {
            setIdToken(credential.signInUserSession.idToken.jwtToken);
        }
    }, [credential]);

    const getAttribute = () => {
        Auth.currentUserInfo().then((result) => {
            setAttribute(result);
        });
    };

    const setPath = () => {
        // pathが/codeでない場合にセッションにpathを保存
        if (String(locate.search).substring(0, 5) != '?code') {
            sessionStorage.setItem('Path', url);
        }
    };

    const getPath = () => {
        const redirectUrl = sessionStorage.getItem('Path');
        sessionStorage.removeItem('Path');
        navigate(redirectUrl);
    };

    const authenticate = () => {
        Amplify.configure({
            Auth: {
                region: 'ap-northeast-1',
                userPoolId: process.env.REACT_APP_USER_POOL_ID,
                userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

                mandatorySignIn: true,
                oauth: {
                    scope: ['email', 'openid', 'phone', 'aws.cognito.signin.user.admin'],
                    domain: process.env.REACT_APP_DOMAIN,
                    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
                    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT,
                    responseType: 'code',
                },
            },
        });
        Auth.currentAuthenticatedUser()
            .then((result) => {
                setCredential(result);
                console.log('Login succeeded!');
                getAttribute();
                setPath();
                getPath();
            })
            .catch((err) => {
                console.log('Login failed!');
                setPath();
                Auth.federatedSignIn({customProvider: 'Okta'});
            });
    };

    return (
        <CredentialContext.Provider value={{getAttribute}}>
            <>{children}</>
        </CredentialContext.Provider>
    );
}
