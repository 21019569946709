import {styled} from '@mui/material/styles';
import MuiSelect from '@mui/material/Select';

export const Select = styled(MuiSelect)((props) => ({
    '.MuiOutlinedInput-notchedOutline': {
        borderRadius: '15px',
        borderColor: '#2E5077',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'solid 2px',
    },
}));
