import * as React from 'react';
export const StateConstantContext = React.createContext();

export default function StateContext({children}) {
    const [authorIdX, setAuthorIdX] = React.useState('');
    const [authorIdIg, setAuthorIdIg] = React.useState('');
    const [authorIdDataX, setAuthorIdDataX] = React.useState('');
    const [authorIdDataIg, setAuthorIdDataIg] = React.useState('');
    const [defaultAuthorIdX, setDefaultAuthorIdX] = React.useState('');
    const [defaultAuthorIdIg, setDefaultAuthorIdIg] = React.useState('');
    const [credential, setCredential] = React.useState('');
    const [attribute, setAttribute] = React.useState('');
    const [idToken, setIdToken] = React.useState('');
    const [name, setName] = React.useState('');
    return (
        <StateConstantContext.Provider
            value={{
                authorIdX,
                setAuthorIdX,
                authorIdIg,
                setAuthorIdIg,
                authorIdDataX,
                setAuthorIdDataX,
                authorIdDataIg,
                setAuthorIdDataIg,
                credential,
                setCredential,
                attribute,
                setAttribute,
                defaultAuthorIdX,
                setDefaultAuthorIdX,
                defaultAuthorIdIg,
                setDefaultAuthorIdIg,
                idToken,
                setIdToken,
                name,
                setName
            }}
        >
            <>{children}</>
        </StateConstantContext.Provider>
    );
}
