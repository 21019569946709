import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Table from '../components/Table';
import axios from 'axios';
import {format} from 'date-fns';
import {StateConstantContext} from '../constants/StateConstant';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import {Link} from 'react-router-dom';

export default function Trends() {
    const url = process.env.REACT_APP_API_URL;
    const [isLoading] = React.useState(false);
    const [googleTrendWords, setGoogleTrendWords] = React.useState([]);
    const [yahooTrendWords, setYahooTrendWords] = React.useState([]);
    const [yahooBoostWords, setYahooBoostWords] = React.useState([]);
    const [createdAt, setCreatedAt] = React.useState(null);
    const {idToken} = React.useContext(StateConstantContext);
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);

    // "&amp;" などの文字列をデコード
    const decodeHtmlEntities = (text) => {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = text;
        return textarea.value;
    };

    // APIでDynamoDBのデータを取得し格納
    const getSocialTrends = () => {
        if (idToken) {
            axios
                .get(url + 'social-trends', {headers: {Authorization: idToken}})
                .then((response) => {
                    if (response.data) {
                        setGoogleTrendWords(response.data.find((el) => el.type == 'google').trend_words);
                        setYahooTrendWords(response.data.find((el) => el.type == 'yahoo').trend_words);
                        setYahooBoostWords(response.data.find((el) => el.type == 'yahoo').boost_words);
                        setCreatedAt(response.data[0].created_at);
                    }
                })
                .catch((error) => {
                    console.log('error', error);
                });
        }
    };

    // MUI DataGrid の形式に変換する
    const dataGridItems = yahooTrendWords.map((yahooItem, index) => {
        const googleItem = googleTrendWords.find((item) => item.ranking === yahooItem.ranking);
        return {
            id: index + 1,
            rank: yahooItem.ranking,
            yahoo: {
                word: decodeHtmlEntities(yahooItem.word),
                ranking: yahooItem.ranking,
            } /* eslint-disable */,
            google: googleItem
                ? {
                      word: decodeHtmlEntities(googleItem.word),
                      url: googleItem.url,
                      ranking: googleItem.ranking,
                  }
                : null,
            /* eslint-disable */
        };
    });

    // APIでデータを取得
    React.useEffect(() => {
        getSocialTrends();
    }, [idToken]);

    const handlePopoverClick = (event) => {
        setPopoverAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
    };

    const popoverOpen = Boolean(popoverAnchorEl);

    const popoverId = popoverOpen ? 'popover' : undefined;

    return (
        <Grid container justifyContent='center' sx={{textAlign: 'center', margin: '0 0 5vh 0'}} spacing={2}>
            <Box sx={{position: 'relative', width: '90%'}}>
                <Box
                    sx={{
                        position: 'absolute',
                        right: 0,
                        margin: '10px 0 0 0',
                        zIndex: '1',
                        textAlign: 'right',
                        fontSize: '16px',
                    }}
                >
                    <Link href='/g-trends' color='inherit'>
                        Googleトレンド比較はこちら
                    </Link>
                </Box>
            </Box>

            {isLoading ? (
                <Box sx={{width: '90%', border: 'solid 1px #2E5077', zIndex: '1', backgroundColor: '#fff', borderRadius: '5px'}}>
                    <CircularProgress sx={{marginTop: '200px'}} />
                </Box>
            ) : (
                <Box id='' sx={{position: 'relative', width: '90%', textAlign: 'left'}}>
                    <Box
                        sx={{
                            position: 'absolute',
                            color: 'black',
                            margin: '48px 0 12px 0',
                            top: 0,
                            right: 0,
                        }}
                    >
                        {createdAt && (
                            <>
                                <p style={{margin: 0, textAlign: 'right'}}>最終更新: {format(new Date(createdAt), 'yyyy/MM/dd HH:mm')}</p>
                            </>
                        )}
                        <Popover
                            id={popoverId}
                            open={popoverOpen}
                            anchorEl={popoverAnchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            sx={{
                                width: 'auto',
                                padding: '0px 10px',
                            }}
                        >
                            <Box sx={{padding: '4px 10px', fontSize: '14px', maxWidth: '500px'}}>
                                <p style={{margin: '6px'}}>更新は約15分間隔です</p>
                                <p style={{margin: '6px'}}>更新時はブラウザをリロードしてください</p>
                                <p style={{margin: '6px'}}>
                                    Xトレンド（Yahoo!データ）：X（旧Twitter）に投稿されたポスト（つぶやき）などの各種データソースを元に、Yahooが機械的に抽出したデータです。「Yahoo!リアルタイム検索」のページでも閲覧できます。
                                </p>
                                <p style={{margin: '6px'}}>
                                    Googleトレンド：過去 24 時間内に Googleサービス全体で話題になったトピックスのデータです。Google
                                    のアルゴリズムで検出されたナレッジグラフのトピック、検索インタレスト、注目の YouTube 動画、Google ニュースの記事が該当します。
                                </p>
                                <p style={{margin: '6px'}}>
                                    X急上昇ワード：「Yahoo!リアルタイム検索」の急上昇ワードを表示しています順不同です。
                                </p>
                            </Box>
                        </Popover>
                        <Tooltip title={`補足`} placement='left' arrow sx={{position: 'absolute', top: '20px', right: '-10px'}}>
                            <IconButton aria-describedby='supplementary information' onClick={handlePopoverClick}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box
                        sx={{
                            color: 'black',
                            margin: '32px 0 12px 0',
                        }}
                    >
                        <span style={{fontSize: 18, fontWeight: 'bold'}}>X急上昇ワード</span>
                        <span style={{fontSize: 14}}>　（Yahoo!リアルタイム検索より）</span>
                    </Box>
                    <Box>
                        <Stack direction='row' spacing={1} useFlexGap flexWrap='wrap'>
                            {yahooBoostWords.map((item, index) => (
                                <a
                                    key={index}
                                    style={{color: 'inherit', textDecoration: 'none', fontSize: ''}}
                                    href={'https://search.yahoo.co.jp/realtime/search?p=' + item.word}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <Chip label={item.word} variant='outlined' sx={{fontSize: ''}} />
                                </a>
                            ))}
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            color: 'black',
                            margin: '32px 0 12px 0',
                        }}
                    >
                        <span style={{fontSize: 18, fontWeight: 'bold'}}>トレンドランキング</span>
                        <span style={{fontSize: 14}}>　(項目をクリックすると詳細ページに遷移します)</span>
                    </Box>
                    <Table dataGridItems={dataGridItems} />
                </Box>
            )}
        </Grid>
    );
}
