import * as React from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {CredentialContext} from '../components/Auth';
import {useNavigate} from 'react-router-dom';
import cockpitIcon from '../../../../src/data/img/cockpit-blue-500x100.svg';
import {StateConstantContext} from '../constants/StateConstant';
import IncrementalSearch from '../components/IncrementalSearch';
import {GetAuthorIdData} from '../components/getAuthorIdData';
import {Button} from '../components/Button';

export default function SetDefaultAccount() {
    const {authorIdX, setAuthorIdX, authorIdDataX, setDefaultAuthorIdX, authorIdIg, setAuthorIdIg, authorIdDataIg, setDefaultAuthorIdIg, attribute, idToken} = React.useContext(StateConstantContext);
    const {getAttribute} = React.useContext(CredentialContext);
    const url = process.env.REACT_APP_API_URL;
    const [username, setUsername] = React.useState('');
    const navigate = useNavigate();
    const [selectedAuthorIdX, setSelectedAuthorIdX] = React.useState('');
    const [selectedAuthorIdIg, setSelectedAuthorIdIg] = React.useState('');
    const [submitFlag, setSubmitFlag] = React.useState(false);
    const channelX = 0;
    const channelIg = 1;
    const cognitoCustomKeys = {
        X: 'custom:def_account_x',
        INSTAGRAM: 'custom:def_account_ig',
    };

    React.useEffect(() => {
        if (attribute) {
            setUsername(attribute.username);
            if (Object.keys(attribute.attributes).includes(cognitoCustomKeys['X'])) {
                setSelectedAuthorIdX(attribute.attributes[cognitoCustomKeys['X']]);
            }
            if (Object.keys(attribute.attributes).includes(cognitoCustomKeys['INSTAGRAM'])) {
                setSelectedAuthorIdIg(attribute.attributes[cognitoCustomKeys['INSTAGRAM']]);
            }
        }
    }, [attribute]);

    const putDataX = async () => {
        return new Promise((resolve, reject) => {
            if (attribute.attributes[cognitoCustomKeys['X']] !== selectedAuthorIdX) {
                // 第二引数に空文字を入れることでbodyを空にする
                axios.put(url + 'default-account/?username=' + username + '&author_id_x=' + selectedAuthorIdX, '', {headers: {Authorization: idToken}}).then((response) => {
                    if (response.status == 200) {
                        setDefaultAuthorIdX(selectedAuthorIdX);
                        setAuthorIdX(selectedAuthorIdX);
                        resolve();
                    } else {
                        alert('登録に失敗しました。画面をリロードして再度お試しください。何度も表示される場合はcockpitのTeamsチャネルにお問い合わせください。');
                        reject(new Error('レスポンスエラーが発生しました'));
                    }
                });
            } else {
                resolve();
            }
        });
    };
    const putDataIg = async () => {
        return new Promise((resolve, reject) => {
            if (attribute.attributes[cognitoCustomKeys['INSTAGRAM']] !== selectedAuthorIdIg) {
                axios.put(url + 'default-account/?username=' + username + '&author_id_ig=' + selectedAuthorIdIg, '', {headers: {Authorization: idToken}}).then((response) => {
                    if (response.status == 200) {
                        setDefaultAuthorIdIg(selectedAuthorIdIg);
                        setAuthorIdIg(selectedAuthorIdIg);
                        resolve();
                    } else {
                        alert('登録に失敗しました。画面をリロードして再度お試しください。何度も表示される場合はcockpitのTeamsチャネルにお問い合わせください。');
                        reject(new Error('レスポンスエラーが発生しました'));
                    }
                });
            } else {
                resolve();
            }
        });
    };

    const onClick = async () => {
        try {
            if (idToken) {
                setSubmitFlag(true);
                const promiseX = putDataX();
                const promiseIg = putDataIg();
                await Promise.all([promiseX, promiseIg]);
                getAttribute();
                alert('設定しました');
                navigate('/');
                return;
            }
        } catch (error) {
            console.log(error);
            alert('登録に失敗しました。画面をリロードして再度お試しください。何度も表示される場合はcockpitのTeamsチャネルにお問い合わせください。');
        } finally {
            setSubmitFlag(false);
        }
    };

    return (
        <>
            <GetAuthorIdData channel={channelX} />
            <GetAuthorIdData channel={channelIg} />
            <Container width='70%'>
                <Grid container justifyContent='center' sx={{margin: '0 0 50 0', textAlign: 'center'}}>
                    <Grid item xs={12}>
                        <Box sx={{margin: '10 0 5 0', fontWeight: 'bold', fontSize: 24}}>SNS運用支援ツール</Box>
                    </Grid>
                    <Grid item xs={12}>
                        <img src={cockpitIcon} alt='cockpit' style={{margin: '0 0 0 0', width: '35%'}}></img>
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' sx={{textAlign: 'center'}}>
                    <Box>
                        <Box sx={{margin: '0 0 20 0', fontSize: '20px', fontWeight: 'normal'}}>デフォルトで表示させたいアカウントを選択してください。</Box>
                    </Box>
                </Grid>
                <Grid container justifyContent='center' sx={{textAlign: 'center'}}>
                    <Box sx={{margin: '0 0 20 0', fontSize: '16px', fontWeight: 'normal', color: 'gray'}}>
                        <Box>Instagramのデフォルトアカウント登録に対応しました。</Box>
                        <Box>お手数ですが、XとInstagramそれぞれ再設定をお願いします。</Box>
                    </Box>
                </Grid>
                <Grid container sx={{margin: '30 0 0 0'}}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6} sx={{textAlign: 'center'}}>
                        <Box>
                            <Box sx={{margin: '0 0 0 0', fontSize: '16px', fontWeight: 'normal'}}>X</Box>
                        </Box>
                    </Grid>
                    <Grid item xs={3}></Grid>
                </Grid>
                <Grid container sx={{margin: '10 0'}}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6} sx={{textAlign: 'center'}}>
                        <Box>
                            <IncrementalSearch item={authorIdDataX} defaultValue={authorIdX} authorId={selectedAuthorIdX} setAuthorId={setSelectedAuthorIdX} />
                        </Box>
                    </Grid>
                    <Grid item xs={3}></Grid>
                </Grid>

                <Grid container sx={{margin: '30 0 0 0'}}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6} sx={{textAlign: 'center'}}>
                        <Box>
                            <Box sx={{margin: '0 0 0 0', fontSize: '16px', fontWeight: 'normal'}}>Instagram</Box>
                        </Box>
                    </Grid>
                    <Grid item xs={3}></Grid>
                </Grid>
                <Grid container sx={{margin: '10 0'}}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6} sx={{textAlign: 'center'}}>
                        <Box>
                            <IncrementalSearch item={authorIdDataIg} defaultValue={authorIdIg} authorId={selectedAuthorIdIg} setAuthorId={setSelectedAuthorIdIg} />
                        </Box>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Button
                        onClick={onClick}
                        sx={{
                            width: '100%',
                            maxWidth: '200px',
                            height: 60,
                            margin: '40px auto',
                        }}
                    >
                        <Box
                            sx={{
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: 16,
                            }}
                        >
                            {submitFlag ? '送信中' : '登録'}
                        </Box>
                    </Button>
                </Grid>
            </Container>
        </>
    );
}
