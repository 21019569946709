import * as React from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/system/Unstable_Grid/Grid';
import {CredentialContext} from '../components/Auth';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const Item = ({username, name, image}) => (
    <Grid lg={3} md={4} xs={6} sx={{justifyContent: 'center', display: 'flex'}}>
        <Box>
            <Avatar src={image && image.replace('normal', '200x200')} alt='Image' sx={{width: 200, height: 200, margin: '43px 0 10px 0'}}></Avatar>
            <Box
                sx={{
                    fontWeight: 'bold',
                    fontSize: 20,
                    textAlign: 'center',
                    margin: '5 0 0 0',
                }}
            >
                {username}
            </Box>
            <Box
                sx={{
                    fontWeight: 'bold',
                    fontSize: 25,
                    textAlign: 'center',
                    margin: '0 0 0 0',
                }}
            >
                {name}
            </Box>
        </Box>
    </Grid>
);

const Admin = (props) => {
    const [state, setState] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const {credential, attribute} = React.useContext(CredentialContext);
    const [idToken, setIdToken] = React.useState(credential.signInUserSession.idToken.jwtToken);
    const channel = 0;

    const onSubmitPost = () => {
        const accountName = document.getElementById('accountName').value;
        axios.post(process.env.REACT_APP_API_URL + 'accounts?account_name=' + accountName, '', {headers: {Authorization: idToken}}).then((response) => {});
    };

    /* JSONの取得*/
    React.useEffect(() => {
        const url = process.env.REACT_APP_API_URL + 'accounts';
        if (idToken) {
            axios
                .get(url + "?channel=" + channel, {headers: {Authorization: idToken}})
                .then((response) => {
                    setState(response.data.Items); /* レスポンスに含まれる array を this.state.array として保持するようにsetState*/
                })
                .catch((error) => {
                    console.log('error');
                });
        }
    }, []);

    React.useEffect(() => {
        const cognitoCustomKeys = {
            'X': 'custom:def_account_x',
            'INSTAGRAM': 'custom:def_account_ig'
        }
        if (attribute) {
            setIdToken(credential.signInUserSession.idToken.jwtToken);
            if (Object.values(cognitoCustomKeys).map((e) => Object.keys(attribute.attributes).some((f) => f.includes(e))).every((e) => e === false)) {
                console.log('default_account is not set');
                navigate('/default_account');
            }
        }
    }, [attribute]);

    return (
        <Container width='70%'>
            <Box
                // container
                justifyContent='center'
                sx={{textAlign: 'center'}}
            >
                <Box
                    sx={{
                        fontWeight: 'bold',
                        fontSize: 26,
                    }}
                >
                    <Box sx={{margin: '10 0 30 0'}}>管理画面</Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    onClick={handleOpen}
                    sx={{
                        width: 200,
                        height: 50,
                        borderRadius: '20px',
                        border: 'solid 5px #eee',
                        background: '#eee',
                        margin: '0 0 0 auto',
                    }}
                >
                    <Box
                        sx={{
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: 18,
                        }}
                    >
                        アカウント追加
                    </Box>
                </Button>
            </Box>
            <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
                <Box sx={style} component='form' noValidate autoComplete='off'>
                    <h3>追加するアカウントを入力してください</h3>
                    <TextField
                        color='info'
                        sx={{
                            margin: '0 10px 0 20px',
                            bgcolor: 'background.paper',
                        }}
                        required
                        id='accountName'
                        label='ユーザー名(@以降)'
                        variant='filled'
                    />
                    <Button
                        type='submit'
                        variant='contained'
                        sx={{
                            margin: '10px',
                        }}
                        onClick={() => onSubmitPost()}
                    >
                        送信
                    </Button>
                </Box>
            </Modal>
            <Grid container spacing={2}>
                {state.length != 0 && state.map((e) => e.name && <Item key={e.username} username={e.username} name={e.name} image={e.profileImagePath} />)}
            </Grid>
        </Container>
    );
};

export default Admin;
