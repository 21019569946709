import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/system/Unstable_Grid/Grid';
import {TwitterTweetEmbed} from 'react-twitter-embed';
import CircularProgress from '@mui/material/CircularProgress';
import {TweetItemContext} from '../../pages/CheckLimit';

const Item = (e) => {
    const {setRowId, setSelectedOverdueTweetID, setSelectedOverduePostId, selectOptionOpen, setSelectedRowsLength, setSelectedOverdueExpiredDate} = React.useContext(TweetItemContext);
    const selected = (f) => {
        setRowId(e.page * 10 + e.index + 1);
        setSelectedOverdueTweetID(e.tweet_id);
        selectOptionOpen();
        setSelectedRowsLength(1);
        setSelectedOverduePostId(e.post_id);
        setSelectedOverdueExpiredDate(e.limit);
    };
    const tweets = () => {
        return (
            <>
                <Grid xs={4} spacing={0}>
                    <Box
                        xs={4}
                        sx={{
                            height: '500px',
                            width: '225px',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                height: '50px',
                                width: '225px',
                                zIndex: '1',
                                position: 'absolute',
                                cursor: 'pointer',
                            }}
                            onClick={selected}
                        ></Box>
                        <Box sx={{fontSize: '14px'}}>ID {e.tweet_id}</Box>
                        <Box sx={{fontSize: '14px'}}>期限 {e.limit.substr(0, 10)}</Box>
                        <Box
                            sx={{
                                position: 'relative',
                                height: '600px',
                                overflow: 'auto',
                                width: '300px',
                                transform: 'scale(0.65, 0.65) translate(-58px, -150px)',
                                position: 'relative',
                                '&::-webkit-scrollbar': {
                                    width: '10px',
                                    height: '10px',
                                    display: 'none',
                                },
                                '&:hover': {
                                    '::-webkit-scrollbar': {
                                        // display: 'inline',
                                    },
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#d2d2d2',
                                    borderRadius: '10px',
                                },
                            }}
                        >
                            <TwitterTweetEmbed
                                id={e.tweet_id}
                                tweetId={e.tweet_id}
                                placeholder={
                                    <CircularProgress
                                        sx={{
                                            marginTop: '100px',
                                        }}
                                    ></CircularProgress>
                                }
                            />
                        </Box>
                    </Box>
                </Grid>
            </>
        );
    };
    return tweets();
};

export default Item;
