import * as React from 'react';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {styled} from '@mui/material/styles';
import {DataGrid} from '@mui/x-data-grid';
import {StateConstantContext} from '../constants/StateConstant';
import IncrementalSearch from '../components/IncrementalSearch';
import {GetAuthorIdData} from '../components/getAuthorIdData';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja';
import {format} from 'date-fns';
import {TwitterTweetEmbed} from 'react-twitter-embed';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';

const Tags = () => {
    // 変数定義
    const navigate = useNavigate();
    const date = new Date();
    const nowDate = new Date();
    const pastDate = date.setDate(date.getDate() - 7);
    const pastDateStr = date.getFullYear() + '-' + ('00' + (date.getMonth() + 1)).slice(-2) + '-' + ('00' + date.getDate()).slice(-2);
    const jst = new Date().toLocaleDateString('ja-JP', {year: 'numeric', month: '2-digit', day: '2-digit'}).replaceAll('/', '-');
    const jstDate = new Date(jst);
    const [channel, setChannel] = React.useState(0);
    const {authorIdX, setAuthorIdX, authorIdIg, setAuthorIdIg, defaultAuthorIdX, defaultAuthorIdIg, authorIdDataX, authorIdDataIg, name, idToken} = React.useContext(StateConstantContext);
    const [authorId, setAuthorId] = React.useState('');
    const [authorIdData, setAuthorIdData] = React.useState('');

    const url = process.env.REACT_APP_API_URL;
    const [isGetPosts, setIsGetPosts] = React.useState(false);
    const [postItems, setPostItems] = React.useState([]);
    const [postNum, setPostNum] = React.useState(0);

    const pageSize = 10;
    const [pageNum, setPageNum] = React.useState(0);

    const [tableKey, setTableKey] = React.useState('');
    const [postRows, setPostRows] = React.useState([]);
    const [loadedPageNum, setLoadedPageNum] = React.useState(0);
    // 選択された行を管理するための状態変数
    const [selectedRowId, setSelectedRowId] = useState(0);

    // ダイアログ表示の状態を管理するための状態変数
    const [open, setOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    // 日付選択の状態を管理するための状態変数
    const [selectedStartDate, setSelectedStartDate] = React.useState(pastDateStr);
    const [selectedEndDate, setSelectedEndDate] = React.useState(jst);
    const [startDate, setStartDate] = React.useState(pastDate);
    const [endDate, setEndDate] = React.useState(nowDate);

    // Tag編集の状態を管理するための状態変数
    const [tagsItems, setTagsItems] = React.useState([]);
    const [tagKeys, setTagKeys] = React.useState([]);
    const [tagKey, setTagKey] = React.useState('');
    const [key, setKey] = React.useState('');
    const [val, setVal] = React.useState('');
    const [isTag, setIsTag] = React.useState(Boolean);
    const [deleteKey, setDeleteKey] = React.useState('');

    const channelType = 0;

    // 初回読み込み時 or 変数更新時に実行
    React.useEffect(() => {
        setChannel(channel);
        if (channel === 0) {
            setAuthorIdData(authorIdDataX);
        } else if (channel === 1) {
            setAuthorIdData(authorIdDataIg);
        }
    }, [authorIdDataX, authorIdDataIg, channel]);

    React.useEffect(() => {
        if (channel === 0) {
            setAuthorId(authorIdX || name || defaultAuthorIdX || '');
        } else if (channel === 1) {
            setAuthorId(authorIdIg || name || defaultAuthorIdIg || '');
        }
    }, [authorIdX, authorIdIg, defaultAuthorIdX, defaultAuthorIdIg, name]);

    React.useEffect(() => {
        if (authorId !== '' && selectedStartDate !== '' && selectedEndDate !== '' && isTag === false) {
            navigate('/tags?name=' + authorId);

            setIsGetPosts(true);
            setPostItems([]);
            setPostNum(0);

            setPageNum(0);

            setTableKey(authorId + selectedStartDate + selectedEndDate);
            setPostRows([]);
            setLoadedPageNum(0);
            setTagKeys([]);

            if (channel === 0 && authorId !== authorIdX) {
                setAuthorIdX(authorId);
            } else if (channel === 1 && authorId !== authorIdIg) {
                setAuthorIdIg(authorId);
            }
        }
    }, [authorId, selectedStartDate, selectedEndDate, isTag]);

    React.useEffect(() => {
        if (authorId !== '' && selectedStartDate !== '' && selectedEndDate !== '' && tagKey !== '') {
            if (tagKey == 'All') {
                setIsTag(false);
            } else {
                setIsTag(true);
                navigate('/tags?name=' + authorId);
                setPostItems([]);
                setPostNum(0);
                setPageNum(0);
                setTableKey(authorId + selectedStartDate + selectedEndDate + tagKey);
                setPostRows([]);
                setLoadedPageNum(0);
                getTagPosts(authorId, pageNum, tagKey);
            }
        }
    }, [tagKey]);

    // authorIdが変更された場合、isTagをfalseにする
    React.useEffect(() => {
        if (isTag !== false) {
            setTagKey('');
            setIsTag(false);
        }
    }, [authorId]);

    React.useEffect(() => {
        if (authorId !== '') {
            if (pageNum > loadedPageNum) {
                setIsGetPosts(true);
                setLoadedPageNum(pageNum);
            } else {
                setIsGetPosts(false);
            }
        }
    }, [pageNum]);

    React.useEffect(() => {
        if (isGetPosts) {
            getPosts(authorId, pageNum);
            setIsGetPosts(false);
            getTagNames(authorId);
        }
    }, [isGetPosts]);

    React.useEffect(() => {
        if (postItems.length > 0) {
            getPostRows(postItems);
        }
    }, [postItems]);

    // 関数定義
    const getPosts = async (authorId, pageNum) => {
        console.log('s', selectedStartDate);
        console.log('E', selectedEndDate);
        if (idToken && authorId) {
            const GetPostsURL = url + 'posts?author_id=' + authorId + '&page=' + pageNum + '&limit=' + pageSize + '&start_date=' + selectedStartDate + '&end_date=' + selectedEndDate;
            const postsResponse = await axios.get(GetPostsURL, {headers: {Authorization: idToken}});
            if (postsResponse.status == 200) {
                setPostItems(postsResponse.data.Items);
                if (postNum === 0) {
                    setPostNum(postsResponse.data.Count);
                }
            }
        }
    };

    const getTagPosts = async (authorId, pageNum, tagKey) => {
        if (idToken && authorId) {
            const GetTagPostsURL = url + 'tags?authorId=' + authorId + '&page=' + pageNum + '&limit=' + pageSize + '&key=' + tagKey;
            const postsResponse = await axios.get(GetTagPostsURL, {headers: {Authorization: idToken}});
            if (postsResponse.status == 200) {
                setPostItems(postsResponse.data.Items);
                if (postNum === 0) {
                    setPostNum(postsResponse.data.Count);
                }
            }
        }
    };

    const getTags = async (authorId, postId) => {
        if (idToken && authorId) {
            const GetTagsURL = url + 'tags?id=' + postId;
            const tagsResponse = await axios.get(GetTagsURL, {headers: {Authorization: idToken}});
            if (tagsResponse.status == 200 && ['Items'] in tagsResponse.data) {
                setTagsItems(tagsResponse.data.Items);
            }
        }
    };

    const getTagNames = async (authorId) => {
        if (idToken && authorId) {
            const tagKeyArray = [];
            tagKeyArray.push('All');
            const GetTagsURL = url + 'tags?authorId=' + authorId;
            const tagsResponse = await axios.get(GetTagsURL, {headers: {Authorization: idToken}});
            if (tagsResponse.status == 200) {
                tagKeyArray.push(...Object.keys(tagsResponse.data));
                setTagKeys(tagKeyArray);
            }
        }
    };

    const getPostRows = (items) => {
        const rows = [];
        items.forEach((item, i) => {
            rows.push({
                id: pageNum * pageSize + i,
                postId: item.id,
                text: item.text,
                created_at: item.created_at,
                edit: 'button',
            });
        });
        setPostRows(postRows.concat(rows));
    };

    const putTag = async (postId, key, value, text, createdAt) => {
        const PutTagsURL = url + 'tags';
        const body = {
            tagKey: key,
            tagVal: value,
            id: postId,
            authorId: authorId,
            channelType: channelType,
            text: text,
            createdAt: createdAt,
        };
        const res = await axios.put(PutTagsURL, body, {headers: {Authorization: idToken}});
        if (res.status == 200) {
            alert('タグを設定しました');
            setKey(''); // キーの初期化
            setVal(''); // 値の初期化
            getTags(authorId, postId);
        }
    };

    const deleteTag = async (postId, key) => {
        console.log(url);
        const DeleteTagsURL = url + 'tags';
        const body = {
            id: postId,
            tagKey: key,
        };
        const res = await axios.delete(DeleteTagsURL, {data: body, headers: {Authorization: idToken}});
        if (res.status == 200) {
            alert('タグを削除しました');
            setDeleteKey(''); // キーの初期化
            getTags(authorId, postId);
            deleteModalHandleClose();
        } else {
            alert('タグの削除に失敗しました');
            deleteModalHandleClose();
        }
    };

    const createTable = () => {
        const columns = [
            {
                field: 'postId',
                headerName: 'ID',
                headerAlign: 'center',
                align: 'center',
                minWidth: 80,
                flex: 2,
                sortable: false,
                filterable: false,
            },
            {
                field: 'text',
                headerName: '投稿内容',
                headerAlign: 'center',
                align: 'center',
                minWidth: 160,
                flex: 4,
                sortable: false,
            },
            {
                field: 'created_at',
                headerName: '投稿日時',
                headerAlign: 'center',
                align: 'center',
                minWidth: 80,
                flex: 2,
                sortable: false,
            },
            {
                field: 'edit',
                headerName: '編集',
                headerAlign: 'center',
                align: 'center',
                minWidth: 40,
                flex: 1,
                disableColumnMenu: true,
                sortable: false,
                filterable: false,
                hideable: false,
                renderCell: (params) => {
                    if (params.row.edit === 'button') {
                        return (
                            <strong>
                                <button
                                    onClick={() => {
                                        handleButtonClick(params.row.id);
                                        getTags(authorId, params.row.postId);
                                    }}
                                >
                                    編集
                                </button>
                            </strong>
                        );
                    } else {
                        return '';
                    }
                },
            },
        ];

        const StyledGridOverlay = styled('div')(() => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        }));

        const customNoRowsOverlay = () => {
            return (
                <StyledGridOverlay>
                    <div style={{fontSize: 16}}>Now Loading...</div>
                </StyledGridOverlay>
            );
        };

        return (
            <DataGrid
                rows={postRows}
                columns={columns}
                key={tableKey}
                rowCount={postNum}
                initialState={{
                    pagination: {
                        paginationModel: {
                            page: Number(pageNum),
                            pageSize: Number(pageSize),
                        },
                    },
                }}
                pageSizeOptions={[Number(pageSize)]}
                onRowSelectionModelChange={(selectedRowId) => {}}
                onPaginationModelChange={(displayedPageNum) => {
                    setPageNum(Number(displayedPageNum.page));
                }}
                slots={{noRowsOverlay: customNoRowsOverlay}}
                loading={false}
                checkboxSelection={false}
                disableRowSelectionOnClick={false}
            />
        );
    };

    const SelectKey = (props) => {
        console.log(props.Key);
        return (
            <Box sx={{margin: '0 auto 20px', textAlign: 'center'}}>
                <FormControl sx={{width: 300, margin: '0 20 0 0'}}>
                    <InputLabel id='output-select-label'>キー選択</InputLabel>
                    <Select
                        labelId='output-select-label'
                        id='output-select'
                        defaultValue={''}
                        label='キー選択'
                        onChange={(event) => {
                            props.setKey(event.target.value);
                        }}
                        value={props.Key}
                    >
                        <MenuItem value='放送日'>放送日</MenuItem>
                        <MenuItem value='番組名'>番組名</MenuItem>
                        <MenuItem value='投稿種類'>投稿種類</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const handleButtonClick = (clickedRowId) => {
        setSelectedRowId(clickedRowId);
        handleOpen();
    };

    const handleDeleteButtonClick = () => {
        setDeleteModalOpen(true);
        deleteModalHandleOpen();
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteModalHandleOpen = () => {
        setDeleteModalOpen(true);
    };

    const deleteModalHandleClose = () => {
        setDeleteModalOpen(false);
    };

    const editTags = () => {
        const selectedRowItem = postRows.filter((row) => row.id === selectedRowId);
        return (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        padding: '0 0 0 0',
                    }}
                    justifyContent='center'
                >
                    <h3 style={{fontSize: '24px', margin: '8px 0'}}>タグ編集</h3>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={5} sx={{display: 'flex', justifyContent: 'center'}}>
                        {selectedRowItem != '' && (
                            <>
                                <Box justifyContent='center' sx={{width: 'calc(100% - 20px)'}}>
                                    <TwitterTweetEmbed
                                        id={selectedRowItem[0]}
                                        tweetId={selectedRowItem[0].postId}
                                        placeholder={
                                            <>
                                                <CircularProgress
                                                    sx={{
                                                        margin: '0 auto 0 100px',
                                                    }}
                                                ></CircularProgress>
                                                <p>{selectedRowItem[0].text}</p>
                                            </>
                                        }
                                    />
                                </Box>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <p>タグ一覧</p>
                                {tagsItems.length == 0 && <p>タグが登録されていません</p>}
                                {tagsItems.length > 0 &&
                                    tagsItems.map((item, i) => {
                                        return (
                                            <p key={i}>
                                                キー{i + 1}: <b>{item.tag_key}</b>&emsp;&emsp;値{i + 1}: <b>{item.tag_val}</b>
                                            </p>
                                        );
                                    })}
                            </Grid>
                            <Grid item xs={12}>
                                <p>タグ登録（値は上書き保存できます）</p>
                            </Grid>
                            <Grid item xs={12}>
                                <SelectKey Key={key} setKey={setKey} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='outlined-value'
                                    label='値'
                                    variant='outlined'
                                    value={val}
                                    onChange={(event) => {
                                        setVal(event.target.value);
                                    }}
                                    sx={{
                                        margin: '0 0 20px 16px',
                                    }}
                                />
                            </Grid>
                            <Box sx={{margin: '0 0 20px 0', display: 'flex', justifyContent: 'center'}}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        margin: '10px',
                                    }}
                                    onClick={() => {
                                        putTag(selectedRowItem[0].postId, key, val, selectedRowItem[0].text, selectedRowItem[0].created_at);
                                    }}
                                >
                                    登録
                                </Button>
                            </Box>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <p>タグ削除</p>
                            </Grid>
                            <Grid item xs={12}>
                                <SelectKey Key={deleteKey} setKey={setDeleteKey} />
                            </Grid>
                            <Box sx={{margin: '0 0 0 0', display: 'flex', justifyContent: 'center'}}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        margin: '10px',
                                    }}
                                    onClick={() => {
                                        if (deleteKey != '') handleDeleteButtonClick();
                                    }}
                                >
                                    削除
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <DeleteModal postId={selectedRowItem.length !== 0 ? selectedRowItem[0].postId : ''} />
            </>
        );
    };

    const DeleteModal = (props) => {
        return (
            <Modal open={deleteModalOpen} onClose={deleteModalHandleClose} aria-labelledby='delete-modal' aria-describedby='modal to check tag deleting'>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        height: '200px',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: '#ffe4e1',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'auto',
                        borderRadius: '15px',
                    }}
                    component='form'
                    noValidate
                    autoComplete='off'
                >
                    <Box
                        sx={{
                            width: '100%',
                            marginLeft: 'auto',
                            textAlign: 'center',
                            padding: {xs: '0px', sm: '4px 2.5vw 0 3vw'},
                        }}
                    >
                        <Box
                            sx={{
                                marginBottom: '28px',
                            }}
                        >
                            {deleteKey} を本当に削除しますか？
                        </Box>
                        <Button
                            variant='contained'
                            sx={{
                                margin: '0 40px 0',
                            }}
                            onClick={() => {
                                deleteModalHandleClose();
                            }}
                        >
                            戻る
                        </Button>
                        <Button
                            variant='contained'
                            color='warning'
                            sx={{
                                margin: '10px',
                            }}
                            onClick={() => {
                                deleteTag(props.postId, deleteKey);
                            }}
                        >
                            削除
                        </Button>
                    </Box>
                </Box>
            </Modal>
        );
    };

    // タグの選択動作
    const handleChange = (e) => {
        setTagKey(e.target.value);
    };

    // タグのスタイル
    const Select = styled(MuiSelect)((props) => ({
        '.MuiOutlinedInput-notchedOutline': {
            borderRadius: '15px',
            borderColor: '#2E5077',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'solid 2px',
        },
    }));

    // タグ絞り込み
    const SelectTag = (props) => {
        return (
            <FormControl sx={{width: 300, margin: '0 20 0 0'}}>
                <InputLabel id='account-select-label'>タグ絞り込み</InputLabel>
                <Select
                    labelId='tag-select-label'
                    id='tag-select'
                    // defaultValue={props.item != '' ? tagKey : 'All'}
                    label='タグ絞り込み'
                    onChange={handleChange}
                    sx={{borderRadius: '50px'}}
                    value={props.item != '' ? tagKey : ''}
                >
                    {props.item.length != 0 &&
                        props.item != undefined &&
                        props.item.map((e, i) => {
                            return (
                                <MenuItem key={i} value={e}>
                                    {e}
                                </MenuItem>
                            );
                            // }
                        })}
                </Select>
            </FormControl>
        );
    };

    // 日付選択のスタイル
    const boxFieldStyles = {
        '.MuiOutlinedInput-notchedOutline': {
            borderRadius: '15px',
            borderColor: '#2E5077',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'solid 2px',
        },
    };

    // 日付選択
    const DatePickerDay = (props) => {
        const dateHandleChange = (newValue) => {
            const formatDay = format(newValue, 'yyyy-MM-dd');
            props.setSelectedDate(formatDay);
            props.setDate(newValue);
        };
        return (
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ja}
                dateFormats={{monthAndYear: 'yyyy年 MM月'}}
                localeText={{
                    previousMonth: '前月を表示',
                    nextMonth: '次月を表示',
                }}
            >
                <Box>
                    <DatePicker label={props.label} maxDate={jstDate} defaultValue={jstDate} value={props.date} sx={boxFieldStyles} onChange={dateHandleChange} />
                </Box>
            </LocalizationProvider>
        );
    };

    return (
        <Container>
            <GetAuthorIdData channel={channel} />
            {/* アカウント選択・日付選択 */}
            <Grid
                container
                justifyContent='center'
                sx={{
                    width: '100%',
                    marginLeft: 'auto',
                    textAlign: 'center',
                    padding: {xs: '0px', sm: '4px 2.5vw 0 3vw'},
                }}
            >
                <Grid item xs={12} sm={12}>
                    <Box sx={{width: 'auto', minWidth: 50, maxWidth: 300, margin: '0 auto'}}>
                        <IncrementalSearch item={authorIdData} defaultValue={authorId} authorId={authorId} setAuthorId={setAuthorId} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box sx={{width: 'auto', minWidth: 50, maxWidth: 300, margin: '20px auto 20px'}}>
                        <DatePickerDay label={'開始日'} date={startDate} setDate={setStartDate} setSelectedDate={setSelectedStartDate} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box sx={{width: 'auto', minWidth: 50, maxWidth: 300, margin: '20px auto 20px'}}>
                        <DatePickerDay label={'終了日'} date={endDate} setDate={setEndDate} setSelectedDate={setSelectedEndDate} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{width: 'auto', minWidth: 50, maxWidth: 300, margin: '20px auto 20px'}}>
                        <SelectTag item={tagKeys} />
                    </Box>
                </Grid>
            </Grid>
            {/* 投稿一覧表 */}
            <Grid
                container
                justifyContent='center'
                sx={{
                    width: '100%',
                    marginLeft: 'auto',
                    textAlign: 'center',
                    padding: {xs: '0px', sm: '4px 2.5vw 0 3vw'},
                }}
            >
                <Grid item xs={12} sm={12}>
                    <Box sx={{width: '100%', height: 640}}>{createTable()}</Box>
                </Grid>
            </Grid>
            {/* 選択した投稿とタグ編集のモーダル表示 */}
            <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description' sx={{overflow: 'scroll'}}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        height: '650px',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'auto',
                        borderRadius: '15px',
                    }}
                    component='form'
                    noValidate
                    autoComplete='off'
                >
                    {editTags()}
                </Box>
            </Modal>
        </Container>
    );
};
export default Tags;

// https://devpediacode.com/information/WEB/9b2fd1f3-666d-5c0f-a1aa-cf1e3e6ac6d2
