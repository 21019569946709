import axios from 'axios';
import * as React from 'react';
import {StateConstantContext} from '../constants/StateConstant';

export const GetAuthorIdData = (props) => {
    const {idToken, setAuthorIdDataX, setAuthorIdDataIg} = React.useContext(StateConstantContext);
    const url = process.env.REACT_APP_API_URL;

    React.useEffect(() => {
        getAccount();
    }, [idToken]);

    const setAuthorIdData = (items) => {
        if (props.channel === 0) {
            setAuthorIdDataX(items);
        } else if (props.channel === 1) {
            setAuthorIdDataIg(items);
        }
    };

    const getAccount = () => {
        if (idToken) {
            axios
                .get(url + 'accounts?channel=' + props.channel, {headers: {Authorization: idToken}})
                .then((response) => {
                    if (response.data.Items) {
                        setAuthorIdData(response.data.Items);
                    }
                })
                .catch((error) => {
                    console.log('error');
                });
        }
    };
};
