import Box from '@mui/material/Box';
import {TwitterTweetEmbed} from 'react-twitter-embed';
import CircularProgress from '@mui/material/CircularProgress';

const selectedTweet = (e, g) => {
    return (
        <>
            <Box sx={{width: '100%', textAlign: 'center'}}>ID {e}</Box>
            <Box sx={{width: '100%', textAlign: 'center'}}>期限 {g.substr(0, 10)}</Box>
            <Box
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    height: '430px',
                    flexWrap: 'wrap',
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '10px',
                        height: '10px',
                        display: 'none',
                    },
                    '&:hover': {
                        '::-webkit-scrollbar': {
                            // display: 'inline',
                        },
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#d2d2d2',
                        borderRadius: '10px',
                    },
                }}
            >
                {e != '' ? <TwitterTweetEmbed key={e} tweetId={e} placeholder={<CircularProgress sx={{marginTop: '50px'}}></CircularProgress>} /> : <p>選択した投稿が表示されます</p>}
            </Box>
        </>
    );
};

const SelectedTweetArea = (props) => {
    return (
        <Box
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                height: '500px',
                flexWrap: 'wrap',
                border: '2px solid #ddd',
                borderRadius: '15px',
            }}
        >
            {selectedTweet(props.selectedOverdueTweetID, props.selectedOverdueExpiredDate)}
        </Box>
    );
};
export default SelectedTweetArea;
